jQuery(document).ready(function(){
	var responsiveNav = $('.rnav');
	var button = $('#rnav-open');

	button.on('change', function(){
		var checkbox = $(this);
		( checkbox.prop('checked') ) ? responsiveNav.attr('style', 'display:none;').slideDown(400) : responsiveNav.attr('style', 'display:block;').slideUp(400);
	});

	if( responsiveNav.length > 0 ) {

		responsiveNav.each(function(){
			var rnav = $(this);
			rnav.on('change', 'input[type="checkbox"]', function(){
				var checkbox = $(this);
				( checkbox.prop('checked') ) ? checkbox.siblings('ul').attr('style', 'display:none;').slideDown(400) : checkbox.siblings('ul').attr('style', 'display:block;').slideUp(400);
			});
		});
	}
});
